import React from "react";
import PropTypes from "prop-types";

import "../../styles/main.scss";
import data from "../../data/layout/content.json";

import Header from "./header";
import Footer from "./footer";

const Layout = ({
  children,
  type,
  content,
  classNames = false,
  hideSecureForm = false,
  showBG = false,
  showFormSecureBanner = false
}) => {
  const createClassList = () => {
    let classList = "l-wrapper";
    if (showBG) classList = classList + " l-wrapper--bg";
    if (classNames) classList = classList + " " + classNames;
    return classList;
  };
  return (
    <div className={createClassList()}>
      <Header
        type={type}
        content={content && content.header ? content.header : data.header}
        hideSecureForm={hideSecureForm}
        showFormSecureBanner={showFormSecureBanner}
      />
      <main>
        <div className="l-container">{children}</div>
      </main>
      <Footer
        pageDisclaimer={
          content && content.footer && content.footer.pageDisclaimer
            ? content.footer.pageDisclaimer
            : false
        }
        pageReferences={
          content && content.footer && content.footer.pageReferences
            ? content.footer.pageReferences
            : false
        }
      />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
