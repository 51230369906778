import React from "react";
import Link from "../atoms/link";

const Footer = ({ pageDisclaimer = false, pageReferences = false }) => {
  
  const paragraphs = (copy) => {
    if (copy.constructor === Array) {
      let paragraphs = [];
      for (let i = 0; i < copy.length; i++) {
        paragraphs.push(
          <p key={i} dangerouslySetInnerHTML={{ __html: copy[i] }} />
        );
      }
      return paragraphs;
    }
    return <p dangerouslySetInnerHTML={{ __html: copy }} />;
  };
  
  const pageDisclaimerContent = pageDisclaimer ? <p dangerouslySetInnerHTML={{ __html: pageDisclaimer }} /> : null;
  const pageReferencesContent = pageReferences ? paragraphs(pageReferences) : null;


  return (
    <div className="co-footer">
      <div className="co-footer__main">
        <div className="l-container">
          <div className="l-grid__row l-grid__middle-xs co-footer__logo-links">
            <div className="l-grid__col-md co-footer__links--secondary">
              <Link url="https://www.lifeextension.com/legal/privacy-notice" target="_blank" classNames="co-footer__link">
                Privacy Policy
              </Link>
              <Link url="https://www.lifeextension.com/legal/legal-notices-01" target="_blank" classNames="co-footer__link">
                Terms &amp; Services
              </Link>
              <Link url="https://www.lifeextension.com/quest-com" target="_blank" classNames="co-footer__link">
                Contact Us
              </Link>
            </div>
          </div>
          <div className="l-grid__row">
            <div className="l-grid__col-xs-12 l-spacing__m-t--24 co-footer__disclaimer">
              {pageDisclaimerContent}

              {pageReferences && (
                <div className="co-footer__references">
                  <p className="u-bold">References:</p>
                  {pageReferencesContent}
                </div>
              )}
              {/* <p>[--Placeholder-Disclaimer-1--]</p>
              <br />
              <p>[--Placeholder-Disclaimer-2--]</p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="co-footer__copyright">
        <div className="l-container">
          <div className="l-grid__row">
            <div className="l-grid__col-xs">
              <p>
                Copyright &copy;{new Date().getFullYear()} Life Extension. All&nbsp;rights&nbsp;reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
