import React from "react";
import SEO from "../components/atoms/seo";

import Layout from "../components/organisms/layout";
import Heading from "../components/molecules/heading";
import BodyCopy from "../components/atoms/body-copy";

const TermsServicesPage = () => (
  <Layout hideSecureForm>
    <SEO title="Terms &amp; Services" />
    <Heading title="Terms &amp; Services" weight="light" />
    <BodyCopy copy={"[--Terms-&-Services--]"} />
  </Layout>
);

export default TermsServicesPage;
