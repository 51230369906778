import React from "react";
import Title from "../atoms/title";

const Heading = ({
  title = "",
  titleType = "h2",
  subTitle = "",
  subTitleType = "h4",
  align = "center",
  color = "dark",
  weight = false
}) => {
  return (
    <div className="cm-heading">
      <Title
        type={titleType}
        title={title}
        align={align}
        color={color}
        weight={weight}
      />
      <Title
        type={subTitleType}
        title={subTitle}
        align={align}
        color={color}
        weight={weight}
      />
    </div>
  );
};

export default Heading;

{
  /* <Heading 
    title="Lorem Ipsum"
    subTitle="Some more lorem ipsum"
    align="center || left || right"
    color="dark || light"
/> */
}
