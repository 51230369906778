import React from "react";
import Link from "../atoms/link";
import lock from "../../images/lock.svg";
import call from "../../images/phone.png";

// const logo2 = "https://place-puppy.com/825x297";
import logo1 from "../../images/le/logo-dark.svg";

const Header = ({
  type = "main",
  content,
  hideSecureForm = false,
  showFormSecureBanner = false
}) => {
  console.log("content: ", content);
  const secureLock = hideSecureForm ? null : (
    <>
      <img src={lock} alt={content.rightColumnText} />
      <span>{content.rightColumnText}</span>
    </>
  );

  const primaryLogo =
    type !== "sds-only" ? (
      <div className="co-header__logo-primary">
        {/* <Link url='/' image> */}
        <img src={logo1} alt="Logo" />
        {/* </Link> */}
      </div>
    ) : null;

  const secureBanner = showFormSecureBanner ? (
    <div className="co-header__banner">{secureLock}</div>
  ) : null;

  return (
    <div className="co-header">
      <div className="co-header__wrapper">
        <div className="co-header__main-menu">
          <div className="l-container">
            <div className="l-grid__row">
              <div className="l-grid__col-xs-7 l-grid__col-md-8 co-header__logo">
                {primaryLogo}
                {/* <div className="co-header__logo-secondary">
                  <img src={logo2} alt="Logo" />
                </div> */}
              </div>

              <div className="l-grid__col-xs-5 l-grid__col-md-4 co-header__secure">
                {secureLock}
              </div>
              {/* <div className='l-grid__col-xs-5 l-grid__col-md-4 co-header__contact'>
								<a href="tel:8552313339">
									<span className="u-desktop__show">(855) 231-3339</span>
									<span className="u-desktop__hide"><img src={call} alt="Contact us icon" /></span>
								</a>
							</div> */}
            </div>
          </div>
        </div>
      </div>
      {/* {secureBanner} */}
    </div>
  );
};

export default Header;
