import React from "react";

const BodyCopy = ({
  copy,
  align = false,
  classNames = false,
  footnote = false,
  small = false
}) => {
  const createClassList = () => {
    let classList = "ca-body-copy";
    if (footnote) classList = classList + " ca-body-copy--footnote";
    if (small) classList = classList + " ca-body-copy--small";

    if (align) classList = classList + " ca-body-copy--" + align;
    if (classNames) classList = classList + " " + classNames;
    return classList;
  };

  const paragraphs = () => {
    if (copy.constructor === Array) {
      let paragraphs = [];
      for (let i = 0; i < copy.length; i++) {
        paragraphs.push(
          <p key={i} dangerouslySetInnerHTML={{ __html: copy[i] }} />
        );
      }
      return paragraphs;
    }
    return <p dangerouslySetInnerHTML={{ __html: copy }} />;
  };

  return <div className={createClassList()}>{paragraphs()}</div>;
};

export default BodyCopy;
